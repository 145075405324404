<template>
  <div class="org-apartments">
    <div class="mol-apartments-filter">
      <p>
        Zimmer:
        <label><input name="rooms-select" type="radio" v-model="selectedRooms" value="All" selected /> Alle</label>
        <label><input name="rooms-select" type="radio" v-model="selectedRooms" value="2" /> 2</label>
        <label><input name="rooms-select" type="radio" v-model="selectedRooms" value="3" /> 3</label>
        <label><input name="rooms-select" type="radio" v-model="selectedRooms" value="4" /> 4</label>
      </p>
      <p>
       Preis:
       <input type="range" min="100000" max="500000" value="100000" name="min-price-select" v-model="selectedMinPrice">
       <input type="range" min="500001" max="1000000" value="10000000" name="max-price-select" v-model="selectedMaxPrice">
     </p>
   </div>
   <div class="mol-grid-row">
    <div class="grid-s-02-span-12">
      <ul class="mol-apartments-list">
        <li   class="elem-apartments-item"
              :class="{ sold : top.sold }"
              v-for="(top, name, index) in this.computedApartments"
              v-show="(selectedRooms == 'All' || selectedRooms == top.rooms) &&
                      (selectedMaxPrice > top.price) &&
                      (selectedMinPrice < top.price)"
              :key="top.name">
        <router-link  :to="'/wohnungen/' + name">
          <img  class="apartment-image"
          :src="top.image.img.path"
          :srcSet="top.image.img.srcSet"
          sizes="100vw, (min-width: 768px) 33vw"
          alt="alt text">
          <div class="apartment-info">
            <h6 class="type-headline-micro smallcaps opacity-06 subtitle">{{ top.name }}</h6>
            <h3 class="type-headline-03 title">{{ top.title }}</h3>
            <p v-if="top.sold" class="price">
              Verkauft
            </p>
            <p v-else class="price">
              {{ top.price | priceFormat }}
            </p>
            <p class="type-copy has-icon opacity-06" v-if="top.floor">
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'atom-image-icon icon-inline floor'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="none" stroke="#e6f2ff" stroke-miterlimit="10" d="M85.84 41.84L50 69.67 14.16 41.84 50 12.67z"/><path fill="none" stroke="#e6f2ff" stroke-miterlimit="10" d="M77.91 55.86l7.93 6.46L50 90.15 14.16 62.32l8.41-6.85"/></svg>
              <span>{{ top.floor }}</span>
            </p>
            <p class="type-copy has-icon opacity-06">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'atom-image-icon icon-inline zimmer'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M12 12h76v76H50V50H12z"/><path class="st0" d="M50 37.3V50h13.1M50 12v13.1M88 50H75.3"/></svg>
              <span> {{ top.rooms }} Zimmer</span>
            </p>
            <p class="type-copy has-icon opacity-06">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'atom-image-icon icon-inline'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M50 12v76M42 12h16M42 88h16M42 50h16M46 31h8M46 69h8"/></svg>
              <span>{{ top.size | areaFormat }}</span>
            <p class="type-copy has-icon opacity-06" v-if="top.specials">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'atom-image-icon icon-inline freiflaeche'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M76.1 24.6l-2.9-10.3-9.3-1.9L50 5.2l-13.8 7.4-9.4 2.7-2.8 9.3-5.6 10.9 2.9 8.2 1.1 6.3 3.7 3.6 6.1 8.9 13 2.4-2 28.4 1.4 1.4h10.6l1.4-1.4-2-28.5H56L68.7 62l5.4-8.3 3.1-3.6 1.6-6.1 2.9-8.3-5.6-11.1z"/></svg>
              <span>{{ top.specials }}</span>
            </p>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</div>
</div>
</template>

<script>
  export default {
    name: 'compApartmentsList',
    data() {
      return {
        selectedRooms: "All",
        selectedMinPrice: 100000,
        selectedMaxPrice: 10000000
      }
    },
    mounted() {

    },
    methods: {
      
    },
    filters: {
      priceFormat(value) {
        let val = (value/1).toFixed(0)
        return "€ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ",—"
      },
      areaFormat(value) {
        return Math.floor(value) + "  qm"
      }
    },
    computed: {
      computedApartments() {
        return this.$store.getters.getterApartments
      },
      filteredApartments() {
        if(this.selectedRooms === "All") {
          return this.computedApartments;
        } else {
          return this.computedApartments.filter(function(apartment) {
            return apartment.rooms == this.selectedRooms;
          });
        }
      }
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.org-apartments {

  padding-top: calc(5*var(--spacer));

  .mol-apartments-filter {
    display: none;
  }

  .mol-apartments-list {
    display: grid;
    grid-gap: var(--spacer);
    grid-template-columns: repeat(1, 1fr);
    padding: var(--spacer);
    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-template-columns: repeat(3, 1fr);
      padding: 0;
    }

    @media screen and (min-width: #{$breakpoint-desktop}) {
      grid-template-columns: repeat(4, 1fr);
    }

    .elem-apartments-item {
      background-color: var( --c-blueblush-06 );
      // border: 1px solid var( --c-blueblush-06 );

      &.sold {
        pointer-events: none;
        opacity: .5;
      }

      .apartment-image {
        width: 100%;
        // height: calc(100vmin - calc(var(--spacer)*2));
        object-fit: cover;

        // @media screen and (min-width: #{$breakpoint-tablet}) {
        //   height: auto;
        // }
      }

      .apartment-info {
        text-align: center;
        padding: calc(var(--spacer) / 2);
        color: var(--c-night-05);

        .price {
          font-size: calc(10px + var(--spacer)*.35);
          color: var(--c-coral-07);
          font-weight: bold;
          margin-bottom: calc(var(--spacer) / 2);
          font-family: 'Grtsk Variable';
          // font-weight: 500;
          font-variation-settings: 'wght' 550, 'wdth' 250;

        }

        .subtitle {

        }

        .has-icon {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-bottom: calc(var(--spacer)*.1);
        }

        .title {
          display: block;
          margin-bottom: calc(var(--spacer) / 2);
          hyphens: auto;

          @media screen and (min-width: #{$breakpoint-laptop}) {
             min-height: 6.9rem;
          }

        }

        // .details {
        //   font-family: 'Grtsk Variable';
        //   display: flex;
        //   align-items: center;
        //   flex-direction: row;
        //   justify-content: center;
        //   vertical-align: top;
        // }

        .atom-image-icon {
          margin-bottom: 0;


          path {
            fill: none;
            stroke: #000;
            stroke-width: 1px;
          }

          &.zimmer {
            margin-right: 0.5rem;
          }

          &.freiflaeche {
            margin-right: 0.3rem;
          }

          &.floor {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

.grid-s-02-span-12 {
  grid-template-rows: repeat(1, auto);

  @media screen and (min-width: #{$breakpoint-tablet}) {
    grid-column: 1 / span 6;
  }

  @media screen and (min-width: #{$breakpoint-laptop}) {
    grid-column: 2 / span 12;
  }
}
</style>