<template>
  <nav class="org-navigation"
       :class="{ 'hidden' : !showNavbar, 'navigation-was-scrolled': navigationWasScrolled }">

    <CompSubNavigationLogo />
    <CompSubNavigationLinks />

  </nav>
</template>

<script>
import CompSubNavigationLogo from '@/components/CompSubNavigationLogo.vue'
import CompSubNavigationLinks from '@/components/CompSubNavigationLinks.vue'

export default {
  data() {
    return {
      showNavbar: true,
      navigationWasScrolled: false,
      lastScrollPosition: 0
    }
  },
  name: 'compNavigation',
  components: {
    CompSubNavigationLogo,
    CompSubNavigationLinks,
  },
  computed: {
    computedIsFirstImageLoaded() {
      return this.$store.getters.getterIsFirstImageLoaded
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  watch: {
    $route (to, from){
      setTimeout(()=>{
        this.navigationWasScrolled = false;
      }, 999);
    }
  },
  methods: {
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      const bodyHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight - 100

      if (currentScrollPosition < 200 || currentScrollPosition > bodyHeight ) {
        document.querySelector('.org-navigation').addEventListener("webkitTransitionEnd", () => {
          this.navigationWasScrolled = false;
        });
        this.navigationWasScrolled = false;

      }

      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
        return
      }


      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
      if (this.navigationWasScrolled) {

        return
      } else if (currentScrollPosition > 200) {
        document.querySelector('.org-navigation').addEventListener("webkitTransitionEnd", () => {
          this.navigationWasScrolled = true;
        });
        // this.navigationWasScrolled = true;
      };
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.org-navigation {
  z-index: 20;

  @media screen and (min-width: #{$breakpoint-tablet}) {
    padding: calc(var(--spacer)*.5) var(--spacer);
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: fixed;
    transform: translate3d(0, 0, 0);
    
    transition: transform .4s var(--easing),
                background-color .4s var(--easing);

    &.hidden {
      transform: translate3d(0, -100%, 0);
    }

    &.navigation-was-scrolled {
      background-color: white;
      // background-color: var(--c-blueblush-05);
;
    }
  }
}
</style>
