<template>
  <div class="org-contact mol-grid-row type-coral type-center">
    <div class="mol-contact grid-s-05-span-06">
        <h6 class="type-headline-micro smallcaps">Kontakt</h6>
        <h2 class="type-headline-02">
          Sie sind an einer Wohnung interessiert oder haben Fragen zum Projekt?
        </h2>
    </div>
    <div class="mol-contact grid-s-06-span-04">
        <p class="type-copy">
          Wir freuen uns über Ihre Kontaktaufnahme und stehen für Ihre Anliegen stets zur Verfügung.
        </p>
    </div>
    <div class="grid-s-06-span-02 button">
      <CompButton   :linkIntern="false"
                    :linkExtern="true"
                    textColor="green"
                    :url="this.$store.state.teammoMailLink"
                    :text="this.$store.state.teammoMailText"
                    icon="mail"
                    :iconOutline="true"
                    class="margin-bottom-micro"/>
    </div>
    <div class="grid-s-08-span-02 button">
      <CompButton   :linkIntern="false"
                    :linkExtern="true"
                    textColor="green"
                    :url="this.$store.state.teammoPhoneLink"
                    :text="this.$store.state.teammoPhoneText"
                    icon="phone"
                    :iconOutline="true"/>
    </div>


 </div>
</template>

<script>

export default {
  data() {
    return {
      
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.org-contact {
  min-height: 80vh;
  background-color: var(--c-blueblush-05);
  padding: calc(var(--spacer)*2) var(--spacer);
  align-content: center;
  box-sizing: border-box;
  margin-bottom: 0;

  @media screen and (min-width: #{$breakpoint-laptop}) {
    padding: var(--spacer);
  }


  .mol-contact {

    *:not(:last-child) {
      margin-bottom: calc( var(--spacer)*.2 );
    }
  }

  .grid-s-06-span-02.button {
    grid-template-rows: repeat(1, auto);

    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: 2 / span 4;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 4 / span 4;
    }

    @media screen and (min-width: #{$breakpoint-desktop}) {
      grid-column: 5 / span 3;
    }
  }

  .grid-s-08-span-02.button {
    grid-template-rows: repeat(1, auto);

    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: 2 / span 4;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 8 / span 4;
    }

    @media screen and (min-width: #{$breakpoint-desktop}) {
      grid-column: 8 / span 3;
    }
  }
}

</style>
