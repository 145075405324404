import Vue from 'vue'
import Router from 'vue-router'
import ViewHome from './views/ViewHome.vue'
import ViewLocation from './views/ViewLocation.vue'
import ViewApartments from './views/ViewApartments.vue'
import ViewContact from './views/ViewContact.vue'
import ViewImprint from './views/ViewImprint.vue'
import ViewPrivacy from './views/ViewPrivacy.vue'

import VueAnalytics from 'vue-analytics'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: ViewHome
    },
    {
      path: '/lage',
      name: 'location',
      // route level code-splitting
      // this generates a separate chunk (location.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "location" */ './views/ViewLocation.vue')
    },
    {
      path: '/wohnungen',
      name: 'apartments',
      // route level code-splitting
      // this generates a separate chunk (apartments.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "apartments" */ './views/ViewApartments.vue')
    },
    {
      path: '/wohnungen/:id',
      name: 'apartments-single',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (apartments.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "apartments" */ './views/ViewApartmentsSingle.vue')
    },
    {
      path: '/kontakt-und-anfragen',
      name: 'contact',
      // route level code-splitting
      // this generates a separate chunk (contact.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "contact" */ './views/ViewContact.vue')
    },
    {
      path: '/impressum',
      name: 'imprint',
      // route level code-splitting
      // this generates a separate chunk (imprint.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "imprint" */ './views/ViewImprint.vue')
    },
    {
      path: '/datenschutz',
      name: 'privacy',
      // route level code-splitting
      // this generates a separate chunk (privacy.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "privacy" */ './views/ViewPrivacy.vue')
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 1000)
    })
  }
})

Vue.use(VueAnalytics, {
  id: 'UA-154881824-1',
  router,
  checkDuplicatedScript: true,
})

export default router;