import { render, staticRenderFns } from "./CompNavigation.vue?vue&type=template&id=05f1d26a&scoped=true&"
import script from "./CompNavigation.vue?vue&type=script&lang=js&"
export * from "./CompNavigation.vue?vue&type=script&lang=js&"
import style0 from "./CompNavigation.vue?vue&type=style&index=0&id=05f1d26a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f1d26a",
  null
  
)

export default component.exports