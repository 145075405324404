<template>
  <div class="temp-contact">    

    <section class="org-section org-section-contact line-bottom line-bottom-padding-none type-coral margin-bottom-medium">
      <div class="mol-grid-row mol-grid-row-contact">
        <div class="grid-s-05-span-08">
          <h5 class="type-headline-pre">Kontakt</h5>
          <h1 class="type-headline-01">Wir beraten Sie gerne</h1>
        </div>

        <div class="grid-s-06-span-05">
          <p class="type-copy">
            Wir freuen uns über Ihre Kontaktaufnahme und stehen für Ihre Anliegen stets zur Verfügung.
          </p>
        </div>

        <div class="grid-s-07-span-04 grid-contact-facts type-green">
          <div class="grid-facts-subgrid">

            <span class="grid-subgrid-s-02-span-03 type-copy">
              teammo projekte GmbH
            </span>

            <span class="grid-subgrid-s-02-span-03 type-copy">
              Wasagasse 11/12<br>
              1090 Wien
            </span>

            <a href="tel:+4313103349" class="grid-subgrid-s-02-span-03 type-copy type-underline">
              +43 1 310 33 49
            </a>

            <a href="mailto:office@teammo.at" class="grid-subgrid-s-02-span-03 type-copy type-underline">
              office@teammo.at
            </a>

            <a href="https://www.teammo.at" target="_blank" class="grid-subgrid-s-02-span-03 type-copy type-underline">
              www.teammo.at
            </a>

          </div>
        </div>

      </div>

    </section>

  

    <CompInquiry />



    
    <CompContact />
    <CompFooter />

  </div>
</template>

<script>
import CompInquiry from '@/components/CompInquiry.vue';

export default {
  name: 'contact',
  data() {
    return {

    }
  },
  metaInfo: {
    title: 'Kontakt & Anfrage',
    htmlAttrs: {
      lang: 'de',
    },
    meta: [
      {name: 'description', content: `Wir freuen uns über Ihre Kontaktaufnahme zu unseren Wohnungen in der Messerschmidtgasse 3, 1180 Wien und stehen für Ihre Anliegen stets zur Verfügung`},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Wir freuen uns über Ihre Kontaktaufnahme zu unseren Wohnungen in der Messerschmidtgasse 3, 1180 Wien und stehen für Ihre Anliegen stets zur Verfügung`},
      {name: 'robots', content: `index, follow`}
    ],
  },
  components: {
    CompInquiry
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>

.temp-contact {
  padding-top: calc(var(--spacer) * 4);

  @media screen and (min-width: #{$breakpoint-tablet}) {
    padding-top: 0;
  }
}

.org-section-contact {
    display: flex;
    min-height: 100vh;

  .mol-grid-row-contact {
    align-self: center;
  }
}

.grid-s-07-span-04.grid-contact-facts {
  grid-template-rows: repeat(1, auto);

  @media screen and (min-width: #{$breakpoint-tablet}) {
    grid-column: 2 / span 4;
  }

  @media screen and (min-width: #{$breakpoint-laptop}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 7 / span 7;
    /* grid-row: 1 / span 3; */
  }
}

.grid-facts-subgrid {
  display: grid;
  grid-row-gap: calc(var(--spacer)*.2);
  grid-column-gap: var(--spacer);
  grid-template-columns: repeat(4, 1fr);

  h5.type-headline-pre,
  span.type-copy {
    line-height: 125%;
  }

  .grid-subgrid-s-01-span-01 {
    grid-column: 1 / span 1;

    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: 2 / span 1;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 1 / span 1;
    }
  }

  .grid-subgrid-s-02-span-03 {
    grid-column: 2 / span 3;

    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: 3 / span 2;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 2 / span 3;
    }
  }
}

</style>