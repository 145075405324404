<template>
  <div  class="org-links"
        :class="{ 'navigation-open': menuIsOpen }"
        v-if="computedIsFirstImageLoaded">

    <transition name="navigation-links-slide-fade" appear>

      <div  class="mol-links type-navigation-normal">
        <div  id="mobile-menu-button"
              class="type-navigation-normal smallcaps"
              @click="toggleMenu">
          <span style="line-height: 35%; display: inline-block; margin-right: calc(var(--spacer)*.25)">
            —<br>
            —<br>
            —
          </span> Menü
        </div>
    
        <transition name="single-link" appear>
          <router-link class="atom-link" id="link-01" to="/">Über das Projekt</router-link>
        </transition>
        <transition name="single-link" appear>
          <router-link class="atom-link" id="link-02" to="/lage">Die Lage</router-link>
        </transition>
        <transition name="single-link" appear>
          <router-link class="atom-link" id="link-03" to="/wohnungen">Die Wohnungen</router-link>
        </transition>
        <transition name="single-link" appear>
          <router-link class="atom-link button-link" id="link-04" to="/kontakt-und-anfragen">Kontakt & Anfrage</router-link>
        </transition>

      </div>

    </transition>

  </div>
</template>

<script>

export default {
  data() {
    return {
      menuIsOpen: false,
    }
  },
  computed: {
    computedIsFirstImageLoaded() {
      return this.$store.getters.getterIsFirstImageLoaded
    },
  },
  methods: {
    toggleMenu() {
      this.menuIsOpen = !this.menuIsOpen 
    }
  },
  mounted() {
    
  },
  watch: {
    $route (to, from){
      this.menuIsOpen = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

:root {
  --mobile-nav-height: calc( var(--spacer)*2 + calc(calc( 15px + var(--spacer)*.05 ) * 1.2) );
  
  @media screen and (min-width: #{$breakpoint-tablet}) {
    --mobile-nav-height: 0;
  }
}

@import '@/assets/css/variables.scss';

.org-links {
  position: fixed;
  bottom: 0;
  z-index: 5;
  width: 100%;
  box-sizing: border-box;

  transform: translateY(100%);
  margin-bottom: var(--mobile-nav-height);

  transition: margin-bottom .6s var(--easing),
              transform     .6s var(--easing);

  &.navigation-open {
    transform: translateY(0);
    margin-bottom: 0;
  }

  @media screen and (min-width: #{$breakpoint-tablet}) {
    transition: none!important;
    position: relative;
    width: auto;
    transform: translateY(0);
    // margin-bottom: var(--spacer);
  }

  .mol-links {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: var(--c-coral-05);

    // transition-delay: 1.2s;

    @media screen and (min-width: #{$breakpoint-tablet}) {
      flex-direction: row;
      background-color: transparent;
    }

    #mobile-menu-button {
      color: white;
      padding: var(--spacer);
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc( 18px + var(--spacer)*.05 );
      line-height: 120%;
      background-color: var(--c-green-04);

      @media screen and (min-width: #{$breakpoint-tablet}) {
        display: none;
      }
    }

    .atom-link {
      color: var(--c-green-03);
      color: white;
      padding: calc(var(--spacer)*.75);
      width: 100%;
      box-sizing: border-box;

      &.router-link-exact-active {
        text-decoration: underline;
      }

      &:first-of-type {
        margin-top: calc(var(--spacer)*.75);
      }

      @media screen and (min-width: #{$breakpoint-tablet}) {
        &:first-of-type {
          margin-top: 0;
        }

        padding: 0;
        color: var(--c-green-03);
        width: auto;
      }

      &.button-link {
        margin-top: calc(var(--spacer)*.5);
        margin-bottom: calc(var(--spacer)*1);
        border: 1px solid var(--c-green-03);
        width: calc(100% - var(--spacer)*2);

        @media screen and (min-width: #{$breakpoint-tablet}) {
          margin-top: 0;
          width: auto;
          margin-bottom: 0;
          padding: calc(var(--spacer)*.25) calc(var(--spacer)*.5);
          border: 1px solid var(--c-coral-05);
        }
      }

      &:not(:last-child) {

        @media screen and (min-width: #{$breakpoint-tablet}) {
          margin-right: var(--spacer);
        }
      }
    }
  }
}

.navigation-links-slide-fade-enter-active, .navigation-links-slide-fade-leave-active {
  transition:   opacity .6s cubic-bezier(0.77, 0, 0.175, 1),
                transform .6s cubic-bezier(0.77, 0, 0.175, 1);
  transition-delay: 1.2s;
}

.navigation-links-slide-fade-enter, .navigation-links-slide-fade-leave-to {
  transform: translateY(calc(var(--spacer)*.5));
  opacity: 0;
}

@media screen and (min-width: #{$breakpoint-tablet}) {
  .navigation-links-slide-fade-enter, .navigation-links-slide-fade-leave-to {
    transform: translateY(calc(var(--spacer)*-.5));
    opacity: 0;
  }
}





  // #link-01 {
  //   transition-delay: 1.2s;
  // }

  // #link-02 {
  //   transition-delay: 1.25s;
  // }

  // #link-03 {
  //   transition-delay: 1.3s;
  // }

  // #link-04 {
  //   transition-delay: 1.35s;
  // }



.fade-enter-active,
.fade-leave-active {


  .single-link-enter-active,
  .single-link-leave-active {
    transition:   opacity .8s cubic-bezier(0.77, 0, 0.175, 1),
                  transform .8s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .single-link-enter,
  .single-link-leave-to {
    transform: translateY(calc(var(--spacer)*.5));
    opacity: 0;
  }

  @media screen and (min-width: #{$breakpoint-tablet}) {
    .single-link-enter,
    .single-link-leave-to {
      transform: translateY(calc(var(--spacer)*-.5));
      opacity: 0;
    }
  }

}



</style>
