<template>
  <div class="temp-location">

    <CompSlider id="slider-location" class="margin-bottom-small" :slides="getterImagesSliderLocation"/>

    <section class="org-section margin-bottom-big line-bottom type-coral">
      <div class="mol-grid-row">
        <div class="grid-s-04-span-08">
          <!-- <h1 class="type-headline-01">5 Minuten: Nahver&shy;sorgung in stimmungs&shy;voller Umgebung</h1> -->
          <h1 class="type-headline-01">5 Minuten: Nahver&shy;sorgung in angenehmer Umgebung</h1>
        </div>

        <div class="grid-s-03-span-05">
          <p class="type-copy">
            In bequemer Gehweite der Hommage au Cottage befinden sich Einkaufsmöglichkeiten und ausgezeichnete Infrastruktur für ein komfortables Leben. Mit der Straßenbahn geht es von der Haustüre direkt in die Wiener Innenstadt.
          </p>
        </div>

        <div class="grid-s-03-span-02 grid-facts grid-location-facts type-green">
          <h5 class="type-headline-pre">5 Minuten Fußweg</h5>
          <ul class="type-copy">
            <li>Supermärkte</li>
            <li>Kindergärten</li>
            <li>Schulen</li>
            <li>Hausärzte</li>
            <li>Gastronomie</li>
            <li>Straßenbahnlinie 9, 40 und 41</li>
          </ul>
        </div>

        <div class="grid-s-05-span-04 grid-location-image">
          <img  class="atom-grid-location-image"
                :style="{ 'object-position': this.imgCouple.objectPostion }"
                :src="this.imgCouple.img.images[Math.round(this.imgCouple.img.images.length / 2)].path"
                :srcset="this.imgCouple.img.srcSet"
                sizes="(min-width: 900px) 33vw, 100vw"
                :alt="this.imgCouple.alt">
        </div>

        <div class="grid-s-06-span-07 grid-location-image">
          <img  class="atom-grid-location-image"
                :style="{ 'object-position': this.imgKutschkermarket.objectPostion }"
                :src="this.imgKutschkermarket.img.images[Math.round(this.imgKutschkermarket.img.images.length / 2)].path"
                :srcset="this.imgKutschkermarket.img.srcSet"
                sizes="(min-width: 900px) 50vw, 100vw"
                :alt="this.imgKutschkermarket.alt">
        </div>

        <div class="grid-s-05-span-04 grid-center">
          <p class="type-copy">
            Als ehemaliger „Vorort“ hat Gersthof bis heute eine entspannte Ruhe beibehalten und liegt dennoch inmitten der Metropole Wien. Die einzigartige Architekturmischung des Viertels an der Grenze vom 18. und 19. Bezirk verleiht der Wohngegend einen mondänen Charakter.
          </p>
        </div>

        <div class="grid-s-09-span-04 grid-location-image">
          <img  class="atom-grid-location-image"
                :style="{ 'object-position': this.imgVilla.objectPostion }"
                :src="this.imgVilla.img.images[Math.round(this.imgVilla.img.images.length / 2)].path"
                :srcset="this.imgVilla.img.srcSet"
                sizes="(min-width: 900px) 33vw, 100vw"
                :alt="this.imgVilla.alt">
        </div>
      </div>

      <div class="mol-location-seperator">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'atom-image-icon'" v-bind:sizes="'100vw, (min-width: 768px) 33vw'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M32.3 71l7.6-16.4c-.4-2-.8-6.1 1.7-8.6l.1-.4.3-12.5-4.5 5.7L35.9 49h-.2l-.9 4.4-3.6 2-.6-.6.6-1.8.4-4.2-1.1-.1 1.2-13.3s6-11.8 7.7-12.8c1.7-.9 7.2-4 7.2-4v-1s.2.1.5.2v-3.7s-1.9-2.1-.8-6.5c1.2-4.4 8.1-4.6 10.2-1.9 2.2 2.7.9 3.9.8 4.8 0 .4.2 1.2.3 1.9 0 0 .9 1.7-.7 2-.2.3-.3.8-.3 2 .1 2.1-2.9 1.3-3.6 1.2v1.8l3.5 3c1.4.9 2.4 1.8 2.8 5.5.6 5.2 1.3 8.4 1.3 8.4l8.1 6.8 2.7 1.9.5 4.1-.8.3-1.4-1.2-3.3-1.8-.9 1.2-8.8-7.2-1.2-2.5-1.1 11.8L79.1 89l5.1-.9 1.6 1.8-10.5 6L49.2 62l-9 16.2-14.1 12.3V93l3.5.8.3 2h-6.5l-5.3-9L32.3 71z"/></svg>
          <span class="type-copy">
            Bis hier sind Sie
          </span>
          <span class="type-headline-03">
            5 Minuten
          </span>
          <span class="type-copy">
            gegangen
          </span>
      </div>
    </section>

    <section class="org-section margin-bottom-big line-bottom type-coral">
      <div class="mol-grid-row">
        <div class="grid-s-07-span-08">
          <h1 class="type-headline-01">10 Minuten: Stadt&shy;nahes Leben inmitten von Grün&shy;flächen</h1>
        </div>

        <div class="grid-s-08-span-05">
          <p class="type-copy">
            Wohnen in Währing verspricht die in der Stadt oft fehlende Naturnähe: zahlreiche öffentliche Freiflächen und vor allem der nahe Türkenschanzpark sorgen für erholsame Spiel- und Freizeitangebote für jedes Alter.
          </p>
        </div>

        <div class="grid-s-03-span-03 grid-facts grid-location-facts type-green">
          <h5 class="type-headline-pre">10 Minuten Fußweg</h5>
          <ul class="type-copy">
            <li>Türkenschanzpark</li>
            <li>Gersthof Stadtbahnstation</li>
            <li>Supermärkte</li>
            <li>Einzelhandel</li>
            <li>Kindergärten</li>
            <li>Schulen</li>
            <li>Gastronomie</li>
            <li>Straßenbahnlinien 9, 40, 41, Bus 10A und 42A und S-Bahn 45</li>
          </ul>
        </div>

        <div class="grid-s-06-span-07 grid-location-image">
          <img  class="atom-grid-location-image"
                :style="{ 'object-position': this.imgFountain.objectPostion }"
                :src="this.imgFountain.img.images[Math.round(this.imgFountain.img.images.length / 2)].path"
                :srcset="this.imgFountain.img.srcSet"
                sizes="(min-width: 900px) 50vw, 100vw"
                :alt="this.imgFountain.alt">
        </div>

        <div class="grid-s-03-span-07 grid-location-image">
          <img  class="atom-grid-location-image"
                :style="{ 'object-position': this.imgChildplay.objectPostion }"
                :src="this.imgChildplay.img.images[Math.round(this.imgChildplay.img.images.length / 2)].path"
                :srcset="this.imgChildplay.img.srcSet"
                sizes="(min-width: 900px) 50vw, 100vw"
                :alt="this.imgChildplay.alt">
        </div>

        <div class="grid-s-06-span-04 grid-center">
          <p class="type-copy">
            Die denkmalgeschützte Station Gersthof ist zu Fuß bequem erreichbar. Ausgehend von der ikonischen Otto Wagner Stadtbahnstation geht es tagsüber alle 10 Minuten in Richtung Hütteldorf und Handelskai. Busse und Straßenbahnen verbinden an zahlreiche Punkte der Stadt.
          </p>
        </div>

        <div class="grid-s-10-span-04 grid-location-image">
          <img  class="atom-grid-location-image"
                :style="{ 'object-position': this.imgGersthof.objectPostion }"
                :src="this.imgGersthof.img.images[Math.round(this.imgGersthof.img.images.length / 2)].path"
                :srcset="this.imgGersthof.img.srcSet"
                sizes="(min-width: 900px) 33vw, 100vw"
                :alt="this.imgGersthof.alt">
        </div>
      </div>

      <div class="mol-location-seperator">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'atom-image-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M32.3 71l7.6-16.4c-.4-2-.8-6.1 1.7-8.6l.1-.4.3-12.5-4.5 5.7L35.9 49h-.2l-.9 4.4-3.6 2-.6-.6.6-1.8.4-4.2-1.1-.1 1.2-13.3s6-11.8 7.7-12.8c1.7-.9 7.2-4 7.2-4v-1s.2.1.5.2v-3.7s-1.9-2.1-.8-6.5c1.2-4.4 8.1-4.6 10.2-1.9 2.2 2.7.9 3.9.8 4.8 0 .4.2 1.2.3 1.9 0 0 .9 1.7-.7 2-.2.3-.3.8-.3 2 .1 2.1-2.9 1.3-3.6 1.2v1.8l3.5 3c1.4.9 2.4 1.8 2.8 5.5.6 5.2 1.3 8.4 1.3 8.4l8.1 6.8 2.7 1.9.5 4.1-.8.3-1.4-1.2-3.3-1.8-.9 1.2-8.8-7.2-1.2-2.5-1.1 11.8L79.1 89l5.1-.9 1.6 1.8-10.5 6L49.2 62l-9 16.2-14.1 12.3V93l3.5.8.3 2h-6.5l-5.3-9L32.3 71z"/></svg>
          <span class="type-copy">
            Bis hier sind Sie
          </span>
          <span class="type-headline-03">
            10 Minuten
          </span>
          <span class="type-copy">
            gegangen
          </span>
      </div>
    </section>

    <section class="org-section margin-bottom-big type-coral">
      <div class="mol-grid-row">
        <div class="grid-s-04-span-08">
          <!-- <h1 class="type-headline-01">20 Minuten: Zwischen Stadt&shy;bummel und Wald&shy;spazier&shy;gang</h1> -->
          <h1 class="type-headline-01">20 Minuten: Shoppen in der Stadt oder spazieren durch den Wald</h1>
        </div>

        <div class="grid-s-08-span-05">
          <p class="type-copy">
            Mit dem Wienerwald im Rücken und der pulsierende Stadt zu Füßen, befinden sich die Wohnungen von Hommage au Cottage in einer einzigartigen Lage. Ob Naherholung oder City-Life, das Angebot ist vielfältig.
          </p>
        </div>

        <div class="grid-s-05-span-03 grid-facts grid-location-facts grid-center type-green">
          <h5 class="type-headline-pre">20 Minuten Fahrzeit</h5>
          <ul class="type-copy">
            <li>Schottentor/Innenstadt</li>
            <li>Volksoper</li>
            <li>Wiener Wald</li>
            <li>Schafbergbad</li>
          </ul>
        </div>

        <div class="grid-s-08-span-04 grid-location-image">
          <img  class="atom-grid-location-image"
                :style="{ 'object-position': this.imgBim.objectPostion }"
                :src="this.imgBim.img.images[Math.round(this.imgBim.img.images.length / 2)].path"
                :srcset="this.imgBim.img.srcSet"
                sizes="(min-width: 900px) 33vw, 100vw"
                :alt="this.imgBim.alt">
        </div>

        <div class="grid-s-04-span-04 grid-location-image">
          <img  class="atom-grid-location-image"
                :style="{ 'object-position': this.imgCity.objectPostion }"
                :src="this.imgCity.img.images[Math.round(this.imgCity.img.images.length / 2)].path"
                :srcset="this.imgCity.img.srcSet"
                sizes="(min-width: 900px) 50vw, 100vw"
                :alt="this.imgCity.alt">
        </div>

        <div class="grid-s-05-span-05 grid-center">
          <p class="type-copy">
            Morgens in der Innenstadt Brunchen und Shoppen, am Nachmittag ausgiebig Wandern im Pötzleinsdorfer Schlosspark und Wienerwald? Hommage au Cottage ermöglicht ein genussvolles Leben ohne Widersprüche mit kurzen Distanzen: von der eigenen Wohnung lassen sich Urbanität und Naturnähe mit bester Anbindung erleben!
          </p>
        </div>
      </div>

      <div class="mol-location-seperator" style="background-color: transparent;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'atom-image-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M11.9 80.7l-.2.2v2.6l.2.2h76.2l.2-.2v-2.6l-.2-.2H11.9zM86.4 36.2l-.5-.5H64.3l11-11v-.3-.1-.3L62.8 11.7v-.1l-.2-.2h-.1l-.1-.1h-.3l-.1.1h-4.6l-.2.2V13l.2.2h4.3l11.2 11.2-11.3 11.4H40.3l-.5.5v2.6h46.5v-2.7zM15.4 48v16.8l-.9.9h-2.9v12h74.8l1.9-12.8-1.9-24.1H11.7v6.3h2.9c.3.4.5.6.8.9zm59.3-.2l1.5-1.5h6.5L84.2 65l-1.5 1.5h-6.5L74.7 65V47.8zM56 48l.9-.9h13.4l.9.9v25.8l-.9.9H56.9l-.9-.9V48zm-18.7 0l.9-.9h13.4l.9.9v16.8l-.9.9H38.2l-.9-.9V48zm-18.5 0l.9-.9H33l.9.9v16.8l-.9.9H19.7l-.9-.9V48z"/></svg>
          <span class="type-copy">
            Bis hier sind Sie
          </span>
          <span class="type-headline-03">
            20 Minuten
          </span>
          <span class="type-copy">
            gefahren
          </span>
      </div>

    </section>


    <CompContact />
    <CompFooter />



   </div>
</template>

<script>
import CompSlider from '@/components/CompSlider.vue'
  
export default {

  name: 'location',
  data() {
    return {
      imgCouple: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Photo-Couple-01.jpg'),
        alt: 'Text Me',
        objectPostion: 'center',
      },
      imgKutschkermarket: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Kutschkermarkt-02.jpg'),
        alt: 'Text Me',
        objectPostion: 'center',
      },
      imgVilla: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Photo-Villa-01.jpg'),
        alt: 'Text Me',
        objectPostion: '100% center',
      },
      imgFountain: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Photo-Tuerkenschanzpark-01.jpg'),
        alt: 'Text Me',
        objectPostion: 'center',
      },
      imgChildplay: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Photo-Childplay-01.jpg'),
        alt: 'Text Me',
        objectPostion: 'center',
      },
      imgGersthof: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Photo-Gersthof-01.jpg'),
        alt: 'Text Me',
        objectPostion: 'center',
      },
      imgBim: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Photo-Bim-01.jpg'),
        alt: 'Text Me',
        objectPostion: 'center',
      },
      imgCity: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-City-02.jpg'),
        alt: 'Text Me',
        objectPostion: 'center',
      },
    }
  },
  metaInfo: {
    title: 'Die Lage',
    htmlAttrs: {
      lang: 'de',
    },
    meta: [
      {name: 'description', content: `Hommage au Cottage liegt inmitten eines erholsamen Wiener Viertels in Wien Währing: der Türkenschanzpark und die denkmalgeschützte Haltestelle Gersthof, liegen in unmittelbarer Umgebung.`},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Hommage au Cottage liegt inmitten eines erholsamen Wiener Viertels in Wien Währing: der Türkenschanzpark und die denkmalgeschützte Haltestelle Gersthof, liegen in unmittelbarer Umgebung.`},
      {name: 'robots', content: `index, follow`}
    ],
  },
  computed: {
    getterImagesSliderLocation() {
      return this.$store.getters.getterImagesLocation
    },
  },
  components: {
    CompSlider
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>

.temp-location {

  .org-logo {
    top: 0;
  }

  #slider-location {
    margin-top: calc(var(--spacer)*5);

    @media screen and (min-width: #{$breakpoint-tablet}) {
      margin-top: 0;
    }
  }

  .grid-location-facts {
    
    &.grid-s-03-span-02 {
      grid-template-rows: repeat(1, auto);

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 2;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-column: 3 / span 2;
        /* grid-row: 1 / span 3; */
      }
    }

    &.grid-s-03-span-03 {
      grid-template-rows: repeat(1, auto);

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 2;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-column: 3 / span 3;
        /* grid-row: 1 / span 3; */
      }
    }

    &.grid-s-05-span-03 {
      grid-template-rows: repeat(1, auto);

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 2;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-column: 5 / span 3;
        /* grid-row: 1 / span 3; */
      }
    }
  }

  .grid-location-image {
    display: inline-grid;
    grid-template-rows: repeat(1, minmax(auto, 50vh));

    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-template-rows: repeat(1, minmax(500px, 50vh));
    }

    .atom-grid-location-image {
      display: block;
      width: 100%;
      height: calc(100vmin - calc(var(--spacer)*2));

      object-fit: cover;

      @media screen and (min-width: #{$breakpoint-tablet}) {
        height: 100%;
        max-height: 100%;
        width: 100%;
      }
    }

    &.grid-s-03-span-07 {

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 4;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 3 / span 7;
      }
    }

    &.grid-s-04-span-04 {

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 2;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 4 / span 4;
      }
    }

    &.grid-s-05-span-04 {

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 2;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 5 / span 4;
      }
    }

    &.grid-s-06-span-07 {

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 4;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 6 / span 7;
      }
    }

    &.grid-s-08-span-04 {

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 4 / span 2;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 8 / span 4;
      }
    }

    &.grid-s-09-span-04 {

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 4 / span 2;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 9 / span 4;
      }
    }

    &.grid-s-10-span-04 {

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 4 / span 2;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 10 / span 4;
      }
    }
  }

  .grid-s-06-span-04 {

    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: 2 / span 3 !important;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 6 / span 4 !important;
    }

    @media screen and (min-width: #{$breakpoint-desktop}) {
      grid-column: 6 / span 4 !important;
    }
  }

  .mol-location-seperator {
    position: absolute;
    bottom: calc( var(--spacer)*-4);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(50%);

    background-color: white;
    z-index: 1;
    width: fit-content;
    padding: calc(var(--spacer)*.5);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

</style>