<template>
  <div class="org-slider">
      <ul class="mol-pagination">
          <li class="atom-pagination-link type-copy type-green"
              v-for="(slide, index) in this.slides"
              :key="'slide-'+index"
              @click="slideTo(index)"
              :class="{ active : index == slideCurrent % slideCount() }">
              <div class="atom-pagination-number-holder" v-if="slide.pagination">
                  {{ slide.pagination }}
              </div>
              <div class="atom-pagination-number-holder smallcaps" v-else>
                {{ index+1 }}
              </div>
          </li>
      </ul>
      <div  class="mol-slider"
            v-for="(slide, index) in this.slides"
            :key="'slide-'+index">
        <transition name="fade" mode="in-out">
          <img  class="atom-slider"
                :style="{ 'object-position': slide.objectPostion, 'object-fit': slide.objectFit }"
                v-show="computedIsFirstImageLoaded && index == slideCurrent % slideCount()"
                :src="slide.img.images[Math.round(slide.img.images.length / 2)].path"
                :srcSet="slide.img.srcSet"
                sizes="100vw"
                :alt="slide.description">

        </transition>

      </div>

 </div>
</template>

<script>

export default {
  data() {
    return {
      slideCurrent: 0,
    }
  },
  name: 'Slider',
  props: {
    slides: {
      type: Array
    }
  },
  computed: {
    computedIsFirstImageLoaded() {
      return this.$store.getters.getterIsFirstImageLoaded
    },
  },
  watch: {
    computedIsFirstImageLoaded() {
      this.startSliderRotation();
    }
  },
  mounted() {
  },
  methods: {
    startSliderRotation() {
      this.timer = setInterval(this.nextSlide, 3500);
    },
    nextSlide() {
      this.slideCurrent += 1
    },
    slideTo(pos) {
      clearInterval(this.timer);
      this.slideCurrent = pos
    },
    slideCount() {
      return this.slides.length
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

:root {
  --mobile-nav-height: calc( var(--spacer)*2 + calc(calc( 15px + var(--spacer)*.05 ) * 1.2) );
  

}

.org-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vmin;

  @media screen and (min-width: #{$breakpoint-tablet}) {
    height: 100vh;
  }

  .mol-pagination {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5vh;
    z-index: 1;
    text-align: center;

    .atom-pagination-link {
      display: inline-block;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: calc(10px + var(--spacer)*.25);
      height: calc(10px + var(--spacer)*.25);
      padding: calc(var(--spacer)*.25);
      // border: 1px solid var(--c-green-03);
      font-family: 'Freight';
      line-height: 100% !important;
      background-color: var(--c-green-03);
      color: white;
      transition: background-color .2s ease-in-out,
                  border .2s ease-in-out,
                  color .2s ease-in-out;

      .atom-pagination-number-holder.smallcaps {
        font-family: 'Freight SC';
        text-transform: lowercase;
      }

      &.active {
        color: white;
        background-color: var(--c-coral-05);
      }

      &:hover {
        cursor: pointer;
        color: white;
        background-color: var(--c-green-05);
      }

      &:not(:last-child) {
        margin-right: calc(var(--spacer)*.5)
      }
    }
  }
  .mol-slider {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img.atom-slider {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

</style>
