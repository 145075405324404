<template>
  <div class="org-button-container">
    
    <router-link  v-if="this.linkIntern"
                  :to="this.url"
                  :class="{ 'has-icon' : icon }"
                  :style="{ 'color': (textColor == 'green') ? 'var(--c-green-05)' : '' }"
                  class="org-button type-button type-coral">
      {{ this.text }}
      <div  v-if="this.icon"
            class="atom-button-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" :class="{ 'icon-fill': iconFill, 'icon-outline' : iconOutline }" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g class="st0"><path class="st1" d="M75.1 63.4c-3.6-1.7-7.9-.7-10.3 2.5-.2.3-.4.5-.6.8-4.3 5.1-9.6 2.7-22.1-7.8-12.4-10.4-15.7-15.3-11.4-20.4.2-.2.4-.5.6-.7 2.7-2.9 3-7.3.7-10.6-1.4-1.9-2.7-3.7-3.4-4.7-4.6-6.1-7.1-13.4-14.2-4.9C6 27.7 6.7 44.7 34.5 68.1c27.8 23.3 44.7 21.1 53.1 11 7.1-8.5-.5-9.7-7.3-13.1-1.1-.6-3.2-1.6-5.2-2.6z"/></g></svg>
      </div>
    </router-link>



    <a  v-if="this.linkExtern"
        :href="this.url"
        :target="this.target"
        :class="{ 'has-icon' : icon }"
        :style="{ 'color': (textColor == 'green') ? 'var(--c-green-05)' : '' }"
        data-action="this.dataAction"
        class="org-button type-button type-coral">
      {{ this.text }}
      <div  v-if="this.icon"
            class="atom-button-icon"
            :class="{ 'icon-fill': iconFill, 'icon-outline' : iconOutline }"
            v-html="this.iconPaths[this.icon]">
      </div>
    </a>

  </div>
</template>

<script>

export default {
  data() {
    return {
      iconPaths : {
        "phone" : `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g class="st0"><path class="st1" d="M75.1 63.4c-3.6-1.7-7.9-.7-10.3 2.5-.2.3-.4.5-.6.8-4.3 5.1-9.6 2.7-22.1-7.8-12.4-10.4-15.7-15.3-11.4-20.4.2-.2.4-.5.6-.7 2.7-2.9 3-7.3.7-10.6-1.4-1.9-2.7-3.7-3.4-4.7-4.6-6.1-7.1-13.4-14.2-4.9C6 27.7 6.7 44.7 34.5 68.1c27.8 23.3 44.7 21.1 53.1 11 7.1-8.5-.5-9.7-7.3-13.1-1.1-.6-3.2-1.6-5.2-2.6z"/></g></svg>`,
        "mail" : `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M6.1 20.7H94v58.6H6.1z"/><path class="st0" d="M6.1 20.7L50 50l43.9-29.3"/></svg>`,
        "locationpin" : `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="st0" d="M25.1 36.7c0 23.8 25.4 52 25.4 52s25.4-28.2 25.4-52c0-16.7-11.4-26.4-25.4-26.4-14 0-25.4 9.7-25.4 26.4zm25.4 10.5C44.1 47.2 39 42 39 35.7s5.2-11.5 11.5-11.5S62 29.3 62 35.7s-5.1 11.5-11.5 11.5"/></svg>`,
        "whatsapp" : `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 99l7.1-26.7c-3.7-7-5.7-14.5-5.7-22.4 0-26.3 21.4-47.7 47.7-47.7 12.8 0 24.9 4.9 33.8 14 9.1 9.1 14 21 14 33.8 0 26.3-21.4 47.7-47.7 47.7-7.5 0-15-1.8-21.6-5.1L2 99zm28.7-14.2l1.2.6c5.9 3.1 12.6 4.8 19.3 4.8 22.3 0 40.4-18.1 40.4-40.4 0-10.9-4.2-21-11.8-28.6C72.2 13.7 62 9.4 51.1 9.4c-22.3 0-40.4 18.1-40.4 40.4 0 7.1 1.8 14 5.4 20.2l.7 1.3-4.7 17.9 18.6-4.4z"/><path d="M72.7 65.6c-1 2.7-5.4 5.1-7.5 5.2-2.1.2-4 1-13.3-2.8-11.1-4.5-18.2-15.8-18.8-16.5-.6-.8-4.6-6-4.6-11.5s2.8-8.1 3.9-9.2c1-1.1 2.2-1.3 2.9-1.3h2.2c.8 0 1.7.1 2.5 1.9 1 2.2 3.1 7.6 3.4 8.2s.5 1.2.1 1.9-.6 1.2-1.1 1.8c-.6.7-1.1 1.4-1.7 1.9-.6.6-1.1 1.1-.5 2.3.7 1.1 2.8 4.7 6.2 7.6 4.2 3.7 7.7 4.8 8.8 5.4 1.1.6 1.7.5 2.4-.2s2.8-3.1 3.5-4.3c.7-1.1 1.4-1 2.5-.6 1 .4 6.5 3 7.5 3.6 1.1.6 1.8.8 2.2 1.2.4.4.4 2.8-.6 5.4z"/></svg>`
      }
    }
  },
  props: {
    linkIntern: Boolean,
    linkExtern: Boolean,
    iconFill : Boolean,
    iconOutline : Boolean,
    url: String,
    text: String,
    icon: String,
    textColor: String,
    target: String,
    dataAction: String,
  },
  mounted() {

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.org-button-container + .org-button-container {
  margin-top: calc(var(--spacer)*.25);
}
.org-button {
  min-height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(var(--spacer)*.25);
  border: 1px solid var(--c-coral-05);
  text-decoration: none;
  position: relative;

  &.has-icon {
    padding: calc(var(--spacer)*.25) calc(var(--spacer)*.75);
  }

  &:hover {
    background-color: var(--c-coral-05);
    color: white !important;
  }

  &.type-button {
    font-family: 'Grtsk Variable';
    font-weight: normal;
    font-style: normal;
    font-size: calc( 12px + var(--spacer)*.05 );
    letter-spacing: .02rem;
    line-height: 130%;
    font-variation-settings: 'wght' 350, 'wdth' 300;
    text-align: center;
  }

  svg {
      width: 100% !important;
    }

  .atom-button-icon {
    position: absolute;
    left: calc(var(--spacer)*.5);
    max-width: 20px;
    fill: var(--c-green-05);
    height: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    color: pink;
    @media screen and (min-width: #{$breakpoint-tablet}) {
      left: calc(var(--spacer)*.25);
    }

    .icon-fill {
      fill: var(--c-green-05);
    }

    

    &.icon-outline {
      svg path {
        stroke-width: 1px;
        stroke: var(--c-green-05);
        fill: none;
      }
    }
     &.icon-fill {
      svg path {
        stroke: none;
        fill: var(--c-green-05);
      }
    }
  }

}

</style>
