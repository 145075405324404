<template>

  <slick class="org-inquiry type-coral margin-bottom-medium"
  ref="slick"
  :options="slickOptions">

  <div>
    <div class="slide1">
      <div class="grid-s-02-span-06">
        <h5 class="type-headline-pre">Anfrage</h5>
        <h1 class="type-headline-01">Schritt für Schritt Leben im Hommage au Cottage</h1>    
      </div>
      <div class="grid-s-03-span-05">
        <p class="type-copy">
          Treten Sie mit uns in Kontakt und erfahren Sie mehr über die Immobilie. Wir garantieren transparente, persönliche Betreuung von der ersten Anfrage bis zum Kaufabschluss.
        </p>
      </div>
      <div class="grid-s-05-span-03 button">
        <CompButton   :linkIntern="false"
        :linkExtern="true"
        textColor="green"
        :url="this.$store.state.teammoMailLink"
        :text="this.$store.state.teammoMailText"
        icon="mail"
        :iconOutline="true"
        class="margin-bottom-micro"/>

        <CompButton   :linkIntern="false"
        :linkExtern="true"
        textColor="green"
        :url="this.$store.state.teammoPhoneLink"
        :text="this.$store.state.teammoPhoneText"
        icon="phone"
        :iconOutline="true" />
      </div>
    </div> <!-- end slide 1 -->
  </div>

  <div>
    <div class="slide2">
      <div class="grid-s-01-span-03 inquiry-step type-center">
        <h6 class="type-headline-micro smallcaps type-coral margin-bottom-micro">Kontakt&shy;aufnahme</h6>
        <p class="type-copy type-bluenight">
          Als kundennahes Unternehmen nehmen wir uns Zeit für Ihre Kaufinteresse per Email oder Telefon.
        </p>
        <span class="inquiry-step-counter">
          1
        </span>
      </div>
      <div class="grid-s-04-span-03 inquiry-step  type-center">
        <h6 class="type-headline-micro smallcaps type-coral margin-bottom-micro">Besichtigung</h6>
        <p class="type-copy type-bluenight">
          Wir stellen Ihnen die Immobilie vor und beraten Sie nach Ihren individuellen Bedürfnissen vor Ort oder bei uns im Büro.
        </p>
        <span class="inquiry-step-counter">
          2
        </span>
      </div>
    </div>
  </div>

  <div>
    <div class="slide2">
      <div class="grid-s-01-span-03 inquiry-step type-center">
        <h6 class="type-headline-micro smallcaps type-coral margin-bottom-micro">Detail&shy;infor&shy;mationen</h6>
        <p class="type-copy type-bluenight">
         Nach Auswahl einer Wohnung erhalten Sie detailierte Pläne sowie die Bau- & Ausstattungs&shy;beschreibungen.
       </p>
       <span class="inquiry-step-counter">
        3
      </span>
    </div>
    <div class="grid-s-04-span-03 inquiry-step  type-center">
      <h6 class="type-headline-micro smallcaps type-coral margin-bottom-micro">Finanzierung</h6>
      <p class="type-copy type-bluenight">
       Sie klären mit der Bank Ihres Vertrauens die Finanzierung ab.
     </p>
     <span class="inquiry-step-counter">
      4
    </span>
  </div>
</div>
</div>

<div>
  <div class="slide2">
    <div class="grid-s-01-span-03 inquiry-step type-center">
      <h6 class="type-headline-micro smallcaps type-coral margin-bottom-micro">Kaufanbot und -Vertrag</h6>
      <p class="type-copy type-bluenight">
        Nach Übermittlung Ihres Anbots erstellen wir Ihren persönlichen Kaufvertrag.
      </p>
      <span class="inquiry-step-counter">
        5
      </span>
    </div>
    <div class="grid-s-04-span-03 inquiry-step  type-center">
      <h6 class="type-headline-micro smallcaps type-coral margin-bottom-micro">Klärung Sonderwünsche</h6>
      <p class="type-copy type-bluenight">
        Je nach Bauphase können wir Ihre individuellen Wünsche in der Ausführung berücksichtigen.
      </p>
      <span class="inquiry-step-counter">
        6
      </span>
    </div>
  </div>
</div>

<div>
  <div class="slide2">
    <div class="grid-s-01-span-03 inquiry-step inquiry-step-last type-center">
     <h6 class="type-headline-micro smallcaps type-coral margin-bottom-micro">WOHNUNGSÜBERGABE</h6>
     <p class="type-copy type-bluenight">
      Mit Bauabschluss übergeben wir Ihnen die Schlüssel zu Ihrer Eigentumswohnung.
    </p>
    <span class="inquiry-step-counter">
      7
    </span>
  </div>
</div>
</div>


</slick>
</template>

<script>
  import Slick from 'vue-slick';  

  export default {
    components: { Slick },
    data() {
      return {
        slickOptions: {
          slidesToScroll: 1,
          slidesToShow: 2,
          arrows: true,
          dots: true,
          variableWidth: true,
          infinite: false,
          centerMode: false,
          prevArrow: '<button type="button" class="slick-prev"> ‹ </button>',
          nextArrow: '<button type="button" class="slick-next"> › </button>',
          responsive: [ 
          {
            breakpoint: 900,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1,
              arrows: true,
            }
          }
          ]

        }
      }
    },
    methods: {
     next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
              this.$refs.slick.reSlick();
            });
          },

        // Events listeners
        handleAfterChange(event, slick, currentSlide) {
          console.log('handleAfterChange', event, slick, currentSlide);
        },
        handleBeforeChange(event, slick, currentSlide, nextSlide) {
          console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
        },
        handleBreakpoint(event, slick, breakpoint) {
          console.log('handleBreakpoint', event, slick, breakpoint);
        },
        handleDestroy(event, slick) {
          console.log('handleDestroy', event, slick);
        },
        handleEdge(event, slick, direction) {
          console.log('handleEdge', event, slick, direction);
        },
        handleInit(event, slick) {
          console.log('handleInit', event, slick);
        },
        handleReInit(event, slick) {
          console.log('handleReInit', event, slick);
        },
        handleSetPosition(event, slick) {
          console.log('handleSetPosition', event, slick);
        },
        handleSwipe(event, slick, direction) {
          console.log('handleSwipe', event, slick, direction);
        },
        handleLazyLoaded(event, slick, image, imageSource) {
          console.log('handleLazyLoaded', event, slick, image, imageSource);
        },
        handleLazeLoadError(event, slick, image, imageSource) {
          console.log('handleLazeLoadError', event, slick, image, imageSource);
        }
      },
      mounted() {
        console.log( Slick );
      }
    }
  </script>

  <style  lang="scss">


  .slick-prev, .slick-next {
    position: absolute;
    z-index: 999;
    left: 0;
    top: 40%;
    width: var(--spacer);
    height: var(--spacer);
    display: block;
    background: var(--c-blueblush-06);
    border: none;
    color: var( --c-bluenight-05);
    text-indent: 0;
    overflow: hidden;
    text-align: center;
    font-size: 1.5em;
    padding: 0;


  }

  .slick-next {
    right: 0;
    left: inherit;
  }

  .slick-dots {
    margin-top: calc(2*var(--spacer));
    with: 100%;
    text-align: center;

    li {
      display: inline-block;
      padding: 0.2em;
    }
    button {
      width: calc(var(--spacer)/4);
      height: calc(var(--spacer)/4);
      display: block;
      background: var(--c-blueblush-06);
      border: 0;
      text-indent: -999em;
      overflow: hidden;
      padding: 0;
    }

    .slick-active button {
      background: var(--c-coral-05);
    }
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


.slick-prev, .slick-next {
  position: absolute;
  left: 0;
  top: 40%;
  width: var(--spacer) !important;
  height: var(--spacer);
  display: block;
  background: var(--c-blueblush-06);
  border: none;
  color: var( --c-bluenight-05);
  text-indent: -999em;

}


.org-inquiry {

  padding-left: 0;
  padding-right: 0;
  display: grid;
  cursor: pointer;

  .mol-hover-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
  }

  .slide1 {
    display: grid;
    grid-gap: var(--spacer);
    grid-template-columns: repeat(8,1fr);
    width: 100vw;

    @media screen and (min-width: #{$breakpoint-tablet}) {
      display: grid;
      grid-template-columns: repeat(8,1fr);
      grid-gap: var(--spacer);
      max-width: 50vw;
      width: auto;
    }

    .grid-s-02-span-06 {
      grid-column: 2 / span 6;

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 6;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 2 / span 6;
      }
    }
    .grid-s-03-span-05 {
      grid-column: 2 / span 6;

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 6;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 2 / span 6;
      }
    }

    .grid-s-05-span-03.button {
      grid-template-rows: repeat(1, auto);
      grid-column: 2 / span 6;

      @media screen and (min-width: #{$breakpoint-tablet}) {
        grid-column: 2 / span 6;
      }

      @media screen and (min-width: #{$breakpoint-laptop}) {
        grid-column: 5 / span 3;
      }
    }
  }


  .slide2 {
    display: block;
    width: 100vw;
    grid-template-columns: repeat(6,1fr);
    grid-gap: var(--spacer);
    @media screen and (min-width: #{$breakpoint-tablet}) {
      max-width: 40vw;
    }
    @media screen and (min-width: #{$breakpoint-laptop}) {
      display: grid;
      grid-template-columns: repeat(6,1fr);
      grid-gap: var(--spacer);
      max-width: 40vw;
      padding: 0;
      margin-top: 200px;
    }

    padding-top: calc(3*var(--spacer));

    .grid-s-01-span-03 {
      padding: var(--spacer);
      @media screen and (min-width: #{$breakpoint-laptop}) {
        padding: 0;
        grid-column: 1 / span 3;
      } 
    }

    .grid-s-04-span-03 {
      margin-top: 100px;
      padding: var(--spacer);

      @media screen and (min-width: #{$breakpoint-laptop}) {
        padding: 0;
        margin-top: 0;
        grid-column: 4 / span 3;
        grid-row: 2;
        margin-top: 0;
      } 
    }

  }


  .inquiry-step {
    position: relative;

    .inquiry-step-counter {
      position: absolute;
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -10;
      color: var(--c-blueblush-06);
      opacity: 0.5;

      // font-family: 'Freight';
      font-family: 'Freight SC';
      text-transform: lowercase;
      font-weight: 100;

      font-size: calc(250px + var(--spacer)*.4);
      margin-bottom: calc(var(--spacer)*.25);
    }


    &.inquiry-step-last {

      .type-copy.type-bluenight {
        color: var(--c-coral-05) !important;
      }


      .inquiry-step-counter {
        font-size: calc(350px + var(--spacer)*.4);
        margin-bottom: calc(var(--spacer)*.25);
        font-weight: bold;
      } 
    }
  }


}

</style>
