import Vue from 'vue'
import Vuex from 'vuex'
import apartmentsJson from '@/assets/json/apartments.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isFirstImageLoaded: false,
    teammoPhoneText: "01 310 33 49",
    teammoPhoneLink: "tel:+4313103349",
    teammoMailText: "office@teammo.at",
    teammoMailLink: "mailto:office@teammo.at",
    sliderHome: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP5_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnung',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front2.jpg'),
        description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
        objectPostion: 'bottom',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Back.jpg'),
        description: 'HOMMAGE AU COTTAGE Hofansicht',
        objectPostion: 'center'
      },
    ],
    sliderApartments: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c02.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnzimmer',
        objectPostion: 'center',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP21_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlafzimmer',
        objectPostion: 'center',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielfreifläche',
        objectPostion: 'center',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Badezimmer',
        objectPostion: 'center',
      },
    ],
    sliderLocation: [
      {
        img: require('@/assets/img/map/HaC-Map-5MIN-2019-11-26-01-mat-01.png'),
        description: 'HOMMAGE AU COTTAGE Karte 5 Minuten Umgebung',
        objectPostion: 'center',
        pagination: '5 Minuten'
      },
      {
        img: require('@/assets/img/map/HaC-Map-10MIN-2019-11-26-01-mat-01.png'),
        description: 'HOMMAGE AU COTTAGE Karte 10 Minuten Umgebung',
        objectPostion: 'center',
        pagination: '10 Minuten'
      },
      {
        img: require('@/assets/img/map/HaC-Map-20MIN-2019-11-26-01-mat-01.png'),
        description: 'HOMMAGE AU COTTAGE Karte 20 Minuten Umgebung',
        objectPostion: 'center',
        pagination: '20 Minuten'
      },
    ],
    apartments: apartmentsJson,
  },
  mutations: {
    loadedFirstImage(state) {
      state.isFirstImageLoaded = true
    }
  },
  getters: {
    getterIsFirstImageLoaded: state => state.isFirstImageLoaded,

    getterImagesHome: state => state.sliderHome,
    getterImagesLocation: state => state.sliderLocation,

    getterImagesSliderApartments: state => state.sliderApartments,

    getterApartments: state => state.apartments
  }
})