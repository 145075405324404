const apartments = {
  "top-1" : {
    name: "TOP 1",
    slug: "top-1",
    title: "Zwei-Zimmer-Wohnung mit Terrasse und Garten",
    rooms: 2,
    floor: "Erdgeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP1.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_1.pdf',
    size: 44.23,
    loggia: 0,
    balcony: 0,
    terrace: 10.89,
    garden: 15.23,
    price: 360000,
    sold: false,
    specials: "Garten und Terrasse",
    description: "Die Wohnung Top 1 befindet sich im Erdgeschoß und ist südseitig zur Thimiggasse ausgerichtet.",
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01-STOERER-03.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum'
    },
     images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c05.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-1.png'),
        description: 'HOMMAGE AU COTTAGE Top 1 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
   ]
  },
  'top-2' : {
    name: "TOP 2",
    slug: "top-2",
    title: "Vier-Zimmer-Wohnung mit Terrasse und Garten",
    rooms: 4,
    floor: "Erdgeschoss",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP2.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_2.pdf',
    size: 86.54,
    loggia: 0,
    balcony: 0,
    terrace: 31.85,
    garden: 43.31,
    price: 700000,
    sold: true,
    specials: "Garten und Terrasse",
    description: "Die Wohnung Top 2 befindet sich im Erdgeschoss und ist südseitig zur Thimiggasse und nordseitig zum Innenhof ausgerichtet.",
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP5_c01-STOERER-04.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten'
    },
     images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP5_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Back.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Hofansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-2.png'),
        description: 'HOMMAGE AU COTTAGE Top 2 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-3' : {
    name: "TOP 3",
    slug: "top-3",
    title: "Zwei-Zimmer-Wohnung mit Loggia",
    rooms: 2,
    floor: "Erdgeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP3.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_3.pdf',
    size: 42.05,
    loggia: 4.51,
    balcony: 0,
    terrace: 0,
    garden: 0,
    price: 295000,
    sold: true,
    specials: "Loggia",
    description: "Die Wohnung Top 3 befindet sich im Erdgeschoß und ist straßenseitig zur Messerschmidtgasse ausgerichtet (durch den Geländeabfall liegt die Wohnung jedoch ca. 2,50 m über dem Gehsteigniveau).",
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum'
    },
     images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c05.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-3.png'),
        description: 'HOMMAGE AU COTTAGE Top 3 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-4' : {
    name: "TOP 4",
    slug: "top-4",
    title: "Vier-Zimmer-Wohnung mit Terrasse und Garten",
    rooms: 4,
    floor: "Erdgeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP4.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_4.pdf',
    size: 97.55,
    loggia: 0,
    balcony: 0,
    terrace: 20.76,
    garden: 21.68,
    price: 730000,
    specials: "Garten und Terrasse",
    description: "Die Wohnung Top 4 befindet sich im Erdgeschoß und ist westseitig zum Innenhof ausgerichtet, das Hauptschlafzimmer ist ostseitig zur Messerschmidtgasse ausgerichtet (durch den Geländeabfall liegt das Schlafzimmer jedoch ca. 2,50 m über dem Gehsteigniveau).",
    sold: true,
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP5_c01.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten'
    },
     images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP5_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Back.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Hofansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-4.png'),
        description: 'HOMMAGE AU COTTAGE Top 4 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-5' : {
    name: "TOP 5",
    slug: "top-5",
    title: "Zwei-Zimmer-Wohnung",
    rooms: 2,
    floor: "1. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP5.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_5.pdf',
    size: 31.15,
    loggia: 0,
    balcony: 0,
    terrace: 0,
    garden: 0,
    price: 250000,
    description: "Die Wohnung Top 5 befindet sich im 1. Stock und ist ostseitig zur Messerschmidtgasse ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP13_c04-STOERER-01.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP13_c04.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten',
        objectPostion: 'center',
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-5.png'),
        description: 'HOMMAGE AU COTTAGE Top 5 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-6' : {
    name: "TOP 6",
    slug: "top-6",
    title: "Zwei-Zimmer-Wohnung mit Balkon",
    rooms: 2,
    floor: "1. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP6.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_6.pdf',
    size: 34.57,
    loggia: 0,
    balcony: 7.69,
    terrace: 0,
    garden: 0,
    price: 300000,
    specials: "Balkon",
    description: "Die Wohnung Top 6 befindet sich im 1. Stock und ist südseitig zur Thimiggasse und ostseitig zur Messerschmidtgasse ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP13_c04.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP13_c04.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum',
        objectPostion: 'center',
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-6.png'),
        description: 'HOMMAGE AU COTTAGE Top 6 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-7' : {
    name: "TOP 7",
    slug: "top-7",
    title: "Zwei-Zimmer-Wohnung mit Loggia und Balkon",
    rooms: 2,
    floor: "1. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP7.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_7.pdf',
    size: 51.54,
    loggia: 3.35,
    balcony: 2.15,
    terrace: 0,
    garden: 0,
    price: 390000,
    specials: "Balkon und Loggia",
    description: "Die Wohnung Top 7 befindet sich im 1. Stock und ist südseitig zur Thimiggasse ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c05.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-7.png'),
        description: 'HOMMAGE AU COTTAGE Top 7 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-8' : {
    name: "TOP 8",
    slug: "top-8",
    title: "Vier-Zimmer-Wohnung mit Balkon",
    rooms: 4,
    floor: "1. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP8.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_8.pdf',
    size: 95.36,
    loggia: 0,
    balcony: 19.34,
    terrace: 0,
    garden: 0,
    price: 740000,
    specials: "großer Balkon",
    description: "Die Wohnung Top 8 befindet sich im 1. Stock und ist südseitig zur Thimiggasse und nordseitig zum Innenhof ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c02.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c02.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Back.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Hofansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-8.png'),
        description: 'HOMMAGE AU COTTAGE Top 8 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-9' : {
    name: "TOP 9",
    slug: "top-9",
    title: "Zwei-Zimmer-Wohnung mit Loggia",
    rooms: 2,
    floor: "1. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP9.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_9.pdf',
    size: 55.09,
    loggia: 4.54,
    balcony: 0,
    terrace: 0,
    garden: 0,
    price: 420000,
    specials: "Loggia",
    description: "Die Wohnung Top 9 befindet sich im 1. Stock und ist ostseitig zur Messerschmidtgasse ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01-STOERER-04.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c05.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-9.png'),
        description: 'HOMMAGE AU COTTAGE Top 9 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-10' : {
    name: "TOP 10",
    slug: "top-10",
    title: "Drei-Zimmer-Wohnung mit Terrasse",
    rooms: 3,
    floor: "1. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP10.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_10.pdf',
    size: 74.45,
    loggia: 0,
    balcony: 23.23,
    terrace: 0,
    garden: 0,
    price: 560000,
    specials: "großer Balkon",
    description: "Die Wohnung Top 10 befindet sich im 1. Stock und ist westseitig zum Innenhof ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP5_c01.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP5_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Back.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Hofansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-10.png'),
        description: 'HOMMAGE AU COTTAGE Top 10 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-11' : {
    name: "TOP 11",
    slug: "top-11",
    title: "Zwei-Zimmer-Wohnung mit Balkon",
    rooms: 2,
    floor: "2. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP11.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_11.pdf',
    size: 31.14,
    loggia: 0,
    balcony: 6.80,
    terrace: 0,
    garden: 0,
    price: 270000,
    specials: "Balkon",
    description: "Die Wohnung Top 11 befindet sich im 2. Stock und ist ostseitig zur Messerschmidtgasse ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP13_c04.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispiel Wohn- und Schlafraum'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP13_c04.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispiel Wohn- und Schlafraum',
        objectPostion: 'center',
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-11.png'),
        description: 'HOMMAGE AU COTTAGE Top 11 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-12' : {
    name: "TOP 12",
    slug: "top-12",
    title: "Zwei-Zimmer-Wohnung mit Balkon",
    rooms: 2,
    floor: "2. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP12.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_12.pdf',
    size: 34.57,
    loggia: 0,
    balcony: 7.69,
    terrace: 0,
    garden: 0,
    price: 315000,
    specials: "Balkon",
    description: "Die Wohnung Top 12 befindet sich im 2. Stock und ist südseitig zur Thimiggasse und ostseitig zur Messerschmidtgasse ausgerichtet.",
    sold: true,
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP13_c04-STOERER-02.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispiel Wohn- und Schlafraum'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP13_c04.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispiel Wohn- und Schlafraum',
        objectPostion: 'center',
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-12.png'),
        description: 'HOMMAGE AU COTTAGE Top 12 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-13' : {
    name: "TOP 13",
    slug: "top-13",
    title: "Zwei-Zimmer-Wohnung mit Loggia und Balkon",
    rooms: 2,
    floor: "2. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP13.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_13.pdf',
    size: 51.54,
    loggia: 3.35,
    balcony: 2.15,
    terrace: 0,
    garden: 0,
    price: 395000,
    specials: "Balkon und Loggia",
    description: "Die Wohnung Top 13 befindet sich im 2. Stock und südseitig zur Thimiggasse ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP14_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c05.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-13.png'),
        description: 'HOMMAGE AU COTTAGE Top 13 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-14' : {
    name: "TOP 14",
    slug: "top-14",
    title: "Vier-Zimmer-Wohnung mit Balkon",
    rooms: 4,
    floor: "2. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP14.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_14.pdf',
    size: 95.36,
    loggia: 0,
    balcony: 19.34,
    terrace: 0,
    garden: 0,
    price: 740000,
    specials: "Großer Balkon",
    description: "Die Wohnung Top 14 befindet sich im 2. Stock und südseitig zur Thimiggasse und nordseitig zum Innenhof ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c02-STOERER-04.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c02.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlaf- und Wohnraum',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Back.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Hofansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-14.png'),
        description: 'HOMMAGE AU COTTAGE Top 14 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-15' : {
    name: "TOP 15",
    slug: "top-15",
    title: "Drei-Zimmer-Wohnung mit Terrasse",
    rooms: 3,
    floor: "2. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP14.svg'),
    pdf: true,
    size: 81.07,
    loggia: 0,
    balcony: 23.23,
    terrace: 0,
    garden: 0,
    price: 720000,
    specials: "Großer Balkon",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c02.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Balkon'
    },
    images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c02.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Balkon',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
    ]
  },
  'top-16' : {
    name: "TOP 16",
    slug: "top-16",
    title: "Zwei-Zimmer-Wohnung mit Balkon",
    rooms: 2,
    floor: "2. Obergeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP14.svg'),
    pdf: false,
    size: 50.24,
    loggia: 0,
    balcony: 5.66,
    terrace: 0,
    garden: 0,
    price: 500000,
    specials: "Riesiger Balkon",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP5_c01.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten'
    },
     images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP5_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnküche mit Terrasse und Garten',
        objectPostion: '50% 100%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
    ]
  },
  'top-17' : {
    name: "TOP 17",
    slug: "top-17",
    title: "Vier-Zimmer Penthouse Wohnung mit Terrasse und Dachterrasse",
    rooms: 4,
    floor: "Penthouse",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP17.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_17.pdf',
    size: 108.19,
    loggia: 0,
    balcony: 0,
    terrace: 16.94,
    rooftop: 60.23,
    garden: 0,
    price: 1080000,
    specials: "60 qm Dachterrasse",
    description: "Die Wohnung Top 17, das Penthouse des Bauteils Messerschmidtgasse ist ostseitig zur Messerschmidtgasse ausgerichtet und westseitig zum Innenhof.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c11-STOERER-02.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnraum'
    },
     images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c11.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnraum',
        objectPostion: '50% 90%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center'
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Back.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Hofansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-17.png'),
        description: 'HOMMAGE AU COTTAGE Top 17 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-18' : {
    name: "TOP 18",
    slug: "top-18",
    title: "Drei-Zimmer-Dachgeschosswohnung mit Terrasse und Balkon",
    rooms: 3,
    floor: "Dachgeschoss",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP18.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_18.pdf',
    size: 84.01,
    loggia: 0,
    balcony: 6.80,
    terrace: 30.07,
    garden: 0,
    price: 760000,
    specials: "Terrasse und Balkon",
    description: "Die Wohnung Top 18 befindet sich im Dachgeschoss und ist südseitig zur Thimiggasse und ostseitig zur Messerschmidtgasse ausgerichtet.",
    sold: true,
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c11.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnraum'
    },
     images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c11.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnraum',
        objectPostion: 'center',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP21_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlafraum',
        objectPostion: 'center',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center',
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-18.png'),
        description: 'HOMMAGE AU COTTAGE Top 18 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-19' : {
    name: "TOP 19",
    slug: "top-19",
    title: "Drei-Zimmer-Dachgeschosswohnung mit Terrasse und Balkon",
    rooms: 3,
    floor: "Dachgeschoß",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP19.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_19.pdf',
    size: 89.34,
    loggia: 0,
    balcony: 0,
    terrace: 22.95,
    garden: 0,
    price: 760000,
    specials: "Terrasse und Balkon",
    description: "Die Wohnung Top 19 befindet sich im Dachgeschoss und ist südseitig zur Thimiggasse und nordseitig zum Innenhof ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c06-LANDSCAPE.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnraum'
    },
     images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispieldachterasse',
        objectPostion: '50% 80%',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c11.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnraum',
        objectPostion: 'center',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center',
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Back.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Hofansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-19.png'),
        description: 'HOMMAGE AU COTTAGE Top 19 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
  'top-20' : {
    name: "TOP 20",
    slug: "top-20",
    title: "Fünf-Zimmer Penthouse Wohnung mit Terrasse",
    rooms: 5,
    floor: "Penthouse",
    floormap: require('@/assets/img/floors/Hommage_au_Cottage-Ebene_svg_TOP20.svg'),
    pdf: '/downloads/expose/HOMMAGE_AU_COTTAGE-Expose-Top_20.pdf',
    size: 117.84,
    loggia: 0,
    balcony: 0,
    terrace: 36.73,
    garden: 0,
    price: 1120000,
    specials: "Große Terrasse",
    description: "Die Wohnung Top 20, das Penthouse des Bauteils Thimiggasse ist südseitig zur Thimiggasse, nordseitig zum Innenhof und ostseitig zur Messerschmidtgasse ausgerichtet.",
    sold: true, 
    image: {
      img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP21_c01.jpg'),
      description: 'HOMMAGE AU COTTAGE Beispielwohnraum'
    },
     images: [
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP21_c01.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielschlafraum',
        objectPostion: 'center',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP18_c11.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielwohnraum',
        objectPostion: 'center',
      },
      {
        img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-TOP15_c06.jpg'),
        description: 'HOMMAGE AU COTTAGE Beispielbadezimmer',
        objectPostion: 'center',
      },
      // {
      //   img: require('@/assets/img/renderings/Hommage_au_Cottag-Rendering-Outdoor-Front.jpg'),
      //   description: 'HOMMAGE AU COTTAGE Außenansicht Messerschmidtgasse 3, 1180 Wien',
      //   objectPostion: 'center'
      // },
      {
        img: require('@/assets/img/plans/Hommage_au_Cottage-Grundriss-top-20.png'),
        description: 'HOMMAGE AU COTTAGE Top 20 Grundriss',
        objectPostion: 'center',
        objectFit: 'contain',
        pagination: 'Grundriss'
      }
    ]
  },
}

export default apartments;