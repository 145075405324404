<template>
  <div class="temp-home">

    <CompSlider class="margin-bottom-small" :slides="computerImagesSliderHome" />

    <section class="org-section margin-bottom-big line-bottom type-coral">
      <div class="mol-grid-row">
        <div class="grid-s-03-span-08">
          <h5 class="type-headline-pre">Das Projekt</h5>
          <h1 class="type-headline-01">Modernes Leben, inspiriert von der Gründerzeit</h1>    
        </div>

        <div class="grid-s-04-span-05">
          <p class="type-copy">
            <span class="type-bluenight">In ruhiger Lage und trotzdem in der Stadt:</span> Das Gersthofer Grätzel überzeugt als Wohnort schon lange mit seinen vielfältigen Vorzügen. Während der Gründerzeit wuchs hier das lebenswerte Cottageviertel. Nun entstehen an selber Stelle im 18. Wiener Bezirk Eigentumswohnungen, smart geschnitten und mit erholsamen Freiflächen: Hommage au Cottage – zeitgemäßes urbanes Leben, in unmittelbarer Naturnähe.
          </p>
        </div>

        <div class="grid-s-05-span-05">
          <p class="type-copy">
            <span class="type-bluenight">Hommage au Cottage</span> ist eine repräsentative Immobilie in der Messerschmidtgasse 3, die 20 Eigentumswohnungen zwischen 30 - 120 qm zum Kauf anbieten. Die elegante Architektur verbindet harmonische Raumkonzepte mit attraktiven Außenbereichen. Durchdachte Grundrisse für alle Lebenslagen kombiniert mit hochwertigen Materialen und geschmackvoller Ausstattung versprechen rundum Wohlbefinden. Hommage au Cottage bietet das ideale Zuhause für alle, die in Erreichbarkeit der pulsierenden Stadt und doch mit dem Rhythmus der Natur leben wollen.
          </p>
        </div>

        <div class="grid-s-11-span-02 grid-facts type-green">
          <h5 class="type-headline-pre">Facts</h5>
          <ul class="type-copy">
            <li>20 Minuten öffentlich in die Stadt</li>
            <li>10 Minuten fußläufig in den Türkenschanzpark</li>
            <li>Großzügige Freiflächen</li>
            <li>Grüner Innenhof</li>
            <li>Garage im Haus</li>
            <li>Fahrradabstellraum mit E-Ladestation</li>
            <li>Verkehrsberuhigte Wohnstraße</li>
            <!-- <li>Bezugsfertig Sommer 2021</li> -->
          </ul>
        </div>
      </div>
    </section>



    <section class="org-section margin-bottom-medium type-bluenight">
      <div class="mol-grid-row image-tiles">
        <div class="grid-s-02-span-04">
          <img  class="image-tile"
                :style="{ 'object-position': this.location05.objectPostion }"
                :src="this.location05.img.images[Math.round(this.location05.img.images.length / 2)].path"
                :srcset="this.location05.img.srcSet"
                sizes="(min-width: 900px) 33vw, 100vw"
                :alt="this.location05.alt">
          <h6 class="type-headline-micro smallcaps opacity-06">{{ this.location05.titlePre }}</h6>
          <h3 class="type-headline-03">{{ this.location05.titleMain }}</h3>
          <p class="type-copy opacity-06">{{ this.location05.description }}</p>
        </div>

        <div class="grid-s-06-span-04">
          <img  class="image-tile"
                :style="{ 'object-position': this.location10.objectPostion }"
                :src="this.location10.img.images[Math.round(this.location10.img.images.length / 2)].path"
                :srcset="this.location10.img.srcSet"
                sizes="(min-width: 900px) 33vw, 100vw"
                :alt="this.location10.alt">
          <h6 class="type-headline-micro smallcaps opacity-06">{{ this.location10.titlePre }}</h6>
          <h3 class="type-headline-03">{{ this.location10.titleMain }}</h3>
          <p class="type-copy opacity-06">{{ this.location10.description }}</p>
        </div>

        <div class="grid-s-10-span-04">
          <img  class="image-tile"
                :style="{ 'object-position': this.location20.objectPostion }"
                :src="this.location20.img.images[Math.round(this.location20.img.images.length / 2)].path"
                :srcset="this.location20.img.srcSet"
                sizes="(min-width: 900px) 33vw, 100vw"
                :alt="this.location20.alt">
          <h6 class="type-headline-micro smallcaps opacity-06">{{ this.location20.titlePre }}</h6>
          <h3 class="type-headline-03">{{ this.location20.titleMain }}</h3>
          <p class="type-copy opacity-06">{{ this.location20.description }}</p>
        </div>
      </div>
    </section>



    <section id="location" class="org-section margin-bottom-medium vh-100 type-green">

      <img  class="image-background"
            style="object-position: center;"
            :src="this.map.img.images[Math.round(this.map.img.images.length / 2)].path"
            :srcset="this.map.srcSet"
            sizes="100vw"
            :alt="this.map.alt">

      <div class="mol-grid-row">

        <div class="grid-s-08-span-05">
          <h5 class="type-headline-pre">Die Lage</h5>
          <h1 class="type-headline-01">Gelassener Flair in urbaner Umgebung</h1>    
        </div>

        <div class="grid-s-09-span-05">
          <p class="type-copy">
            Hommage au Cottage liegt inmitten eines erholsamen Wiener Viertels: Von der Haustüre führen baumgesäumte Straßen durch das atmosphärische Cottageviertel vorbei an Gründerzeitbauten und Jugendstilvillen. Der Spaziergang entlang der Grenze zwischen 18. und 19. Bezirk lässt sich im weitläufigen Türkenschanzpark fortsetzen oder führt zur denkmalgeschützten Haltestelle Gersthof, dem zentralen Verkehrsknotenpunkt von Währing. Der Bus 10A und die Vorortlinie S 45 verbinden sowohl in Richtung Heiligenstadt wie auch Hütteldorf, die Straßenbahnlinien 40 und 41 führen direkt in die Innenstadt.   
          </p>
        </div>

        <div class="grid-s-10-span-03 button">
          <CompButton   :linkIntern="true"
                        :linkExtern="false"
                        textColor="green"
                        url="/lage"
                        text="Mehr über die Lage"
                        icon=""
                        class="margin-bottom-xs"/>

          <CompButton   :linkIntern="false"
                        :linkExtern="true"
                        textColor="green"
                        url="https://www.google.com/maps?q=Messerschmidtgasse+3&rlz=1C5CHFA_enAT826AT826&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiWoo2I44zmAhXj8KYKHfaGAXEQ_AUoAXoECA0QAw"
                        text="In Google Maps öffnen"
                        icon="locationpin"
                        :iconOutline="true"
                        target="_blank"/>
        </div>
      </div>
    </section>


    <CompSlider class="margin-bottom-medium" :slides="this.$store.getters.getterImagesSliderApartments" />


    <section  class="org-section margin-bottom-medium type-coral"
              style="align-content: center;">
      <div class="mol-grid-row">
        <div class="grid-s-04-span-05">
          <h5 class="type-headline-pre">Die Wohnungen</h5>
          <h1 class="type-headline-01">Sonniges Zuhause mit Ausblick</h1>    
        </div>

        <div class="grid-s-03-span-05">
          <p class="type-copy">
            Hommage au Cottage verfügt über insgesamt 20 moderne Eigentumswohnungen, die mit <span class="type-bluenight">eigener Terrasse, eigenem Garten oder Balkon</span> ausgestattet sind. Die smarte Bauweise sorgt für viel Lebensqualität durch das Zusammenspiel von lichtdurchflutetem Innenleben und privaten Freiflächen. Unterschiedliche Wohnungsgrößen bieten individuell passenden Rückzugsraum für jede Lebenslage. Die hochwertige und zeitgemäße Ausstattung verspricht hohen Komfort und entspanntes Wohnen in einem stimmungsvollen Gesamtensemble.
          </p>
        </div>

        <div class="grid-s-04-span-03 button">
          <CompButton   :linkIntern="true"
                        :linkExtern="false"
                        textColor="green"
                        url="/wohnungen"
                        text="Alle Wohnungen anzeigen"
                        icon=""/>
        </div>


        <div class="grid-s-09-span-04 grid-flats">
          <router-link to="/wohnungen" class="grid-flats-left">
            <span class="type-flat-counter type-coral">2</span>
            <span class="type-headline-03 margin-bottom-micro type-coral">Zimmer</span>
            <p class="type-copy type-green opacity-06">
              10 Wohnungen<br>
              zwischen 30 und 55 qm<br>
              Terrasse, Balkon oder Loggia<br>
            </p>
          </router-link>

          <router-link to="/wohnungen" class="grid-flats-right">
            <span class="type-flat-counter type-coral">3</span>
            <span class="type-headline-03 margin-bottom-micro type-coral">Zimmer</span>
            <p class="type-copy type-green opacity-06">
              4 Wohnungen<br>
              zwischen 75 und 90 qm<br>
              Balkon, Terrasse oder Dachterrasse<br>
            </p>
          </router-link>

          <router-link to="/wohnungen" class="grid-flats-left">
            <span class="type-flat-counter type-coral">4 – 5</span>
            <span class="type-headline-03 margin-bottom-micro type-coral">Zimmer</span>
            <p class="type-copy type-green opacity-06">
              6 Wohnungen<br>
              zwischen 85 und 120 qm<br>
              Garten, Balkon, Terrasse oder Dachterrasse<br>
            </p>
          </router-link>
        </div>

      </div>
    </section>


    <CompContact />
    <CompFooter />



   </div>
</template>

<script>
import CompSlider from '@/components/CompSlider.vue'

export default {

  name: 'home',
  data() {
    return {
      location05: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Kutschkermarkt-02.jpg'),
        objectPostion: "0% center",
        alt: "In 5 Minuten einkaufen, zum Kindergarten und in die Schule",
        titlePre: "Nahversorgung",
        titleMain: "In 5 Minuten einkaufen, zum Kindergarten und in die Schule",
        description: "In unmittelbarer Gehnähe: Restaurants, Fachhandel, Supermärkte, medizinische Versorgung und Bildungseinrichtungen",
      },
      location10: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Photo-Tuerkenschanzpark-01.jpg'),
        objectPostion: "center",
        alt: "In 10 Minuten ins Grüne",
        titlePre: "Natur",
        titleMain: "In 10 Minuten in den Park und direkt ins Grüne",
        description: "In einem kurzen Spaziergang geht es zu Türkenschanzpark, Universitätssternwarte und weiteren kleineren Grünflächen",
      },
      location20: {
        img: require('@/assets/img/photos/Hommage_au_Cottage-Photo-Bim-01.jpg'),
        objectPostion: "center",
        alt: "",
        titlePre: "Nahverkehr",
        titleMain: "In 20 Minuten mit den Öffis in die Innenstadt",
        description: "Ob von der ikonischen Otto Wagner Haltestelle mit der S-Bahn oder mit der Bim: gut angeschlossen an das urbane Leben",
      },
      map: {
        img: require('@/assets/img/map/HaC-Map-10MIN-2019-11-26-01-mat-01.png'),
        alt: 'Hommage au Cottage Karte 20 Minuten Umgebung',
        objectPostion: 'center',
      },
    }
  },
  metaInfo: {
    title: 'Das Projekt',
    htmlAttrs: {
      lang: 'de',
    },
    meta: [
      {name: 'description', content: `Hommage au Cottage ist eine repräsentative Immobilie in der Messerschmidtgasse 3, 1180 Wien die sich aus 20 Eigentumswohnungen zwischen 30 - 120 qm zusammensetzt. `},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Hommage au Cottage ist eine repräsentative Immobilie in der Messerschmidtgasse 3, die sich aus 20 Eigentumswohnungen zwischen 30 - 120 qm zusammensetzt. `},
      {name: 'robots', content: `index, follow`}
    ],
  },
  computed : {
    computedIsFirstImageLoaded() {
      return this.$store.getters.getterIsFirstImageLoaded
    },
    computerImagesSliderHome() {
      return this.$store.getters.getterImagesHome
    },
  },
  components: {
    CompSlider,
  },
  mounted() {

  }
}
</script>

<style lang="scss">

#location.margin-bottom-medium {
  @media screen and (min-width: #{$breakpoint-laptop}) {
    margin-bottom: 0;
  }
}

.image-tiles {
  justify-content: center;
  text-align: center;

  *:not(:last-child) {
    margin-bottom: calc( var(--spacer)*.2 );
  }

  .image-tile {
    width: 100%;
    height: calc(100vmin - calc(var(--spacer)*2));
    object-fit: cover;
    margin-bottom: calc( var(--spacer)*.5 );

    @media screen and (min-width: #{$breakpoint-tablet}) {
      height: 33vw;
    }
  }

  .grid-s-02-span-04 {
    grid-template-rows: repeat(1, auto);

    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: 1 / span 2;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 2 / span 4;
    }
  }

  .grid-s-06-span-04 {
    grid-template-rows: repeat(1, auto);

    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: 3 / span 2;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 6 / span 4;
    }
  }

  .grid-s-10-span-04 {
    grid-template-rows: repeat(1, auto);

    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: 5 / span 2;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 10 / span 4;
    }
  }
}

.grid-flats {
  display: grid;
  grid-gap: var(--spacer);
  margin-top: calc(var(--spacer));

  @media screen and (min-width: #{$breakpoint-tablet}) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (min-width: #{$breakpoint-laptop}) {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 0;
  }

  .grid-flats-left {
    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: span 2;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 1 / span 3;
    }
  }

  .grid-flats-right {
    @media screen and (min-width: #{$breakpoint-tablet}) {
      grid-column: span 2;
    }

    @media screen and (min-width: #{$breakpoint-laptop}) {
      grid-column: 2 / span 3;
    }
  }
}

</style>