<template>
  <div class="temp-apartments">

    <CompApartmentsList class="margin-bottom-small" />
    

    <CompContact />
    <CompFooter />
    
  </div>
</template>

<script>
import CompApartmentsList from '@/components/CompApartmentsList.vue';

export default {
  name: 'apartments',
  data() {
    return {

    }
  },
  metaInfo: {
    title: 'Die Wohnungen',
    htmlAttrs: {
      lang: 'de',
    },
    meta: [
      {name: 'description', content: `Das Projekt Hommage au Cottage verfügt über insgesamt 19 moderne Wohneinheiten, die mit eigener Terrasse, eigenem Garten oder Balkon ausgestattet sind.`},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Das Projekt Hommage au Cottage verfügt über insgesamt 19 moderne Wohneinheiten, die mit eigener Terrasse, eigenem Garten oder Balkon ausgestattet sind.`},
      {name: 'robots', content: `index, follow`}
    ],
  },
  components: {
    CompApartmentsList
  },
  mounted() {

  }
}
</script>

<style lang="scss">

</style>