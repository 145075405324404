<template>
  <div class="temp-imprint">
    
    <section class="org-section margin-bottom-medium type-coral">
      <div class="mol-grid-row">
        <div class="grid-s-03-span-08">
          <h1 class="type-headline-01">Impressum</h1>    
        </div>

        <div class="grid-s-03-span-04">
          <p class="type-copy">
            
            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre margin-bottom-xs">Hommage au Cottage ist ein Projekt von</h5>
              teammo projekte GmbH
            </span><br>

            <span class="margin-bottom-xs atom-item">
              Wasagasse 11/12<br>
              1090 Wien, Österreich
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <a href="tel:+4313103349" class="type-underline">
              +43 1 310 33 49
            </a>
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <a href="mailto:office@teammo.at" class="type-underline">
                office@teammo.at
              </a>
            </span><br>

            <span class="margin-bottom-small atom-item">
              <a href="https://www.teammo.at" class="type-underline">
                www.teammo.at
              </a>
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">UID-Nummer</h5>
              ATU73361104
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">Firmenbuchnummer</h5>
              FN489662 t
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">Firmenbuchgericht</h5>
              Handelsgericht Wien
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">Firmensitz</h5>
              1090 Wien
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">Geschäftszweig</h5>
              Immobilienentwicklung
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">Verleihungsstaat</h5>
              Österreich
            </span><br>

            <span class="margin-bottom-small atom-item">
              <h5 class="type-headline-pre">Geschäftsführer</h5>
              Lukas Adami, MMSc<br>
              Ing. Benjamin Fercher, BA
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">Architektur</h5>
              <a href="https://www.gap-zt.at/" target="_blank" class="type-underline">
                  GAP ZT GmbH
              </a>
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">Identity, Design & Code</h5>
              <a href="https://buerobauer.com/" target="_blank" class="type-underline">
                buero bauer
              </a>
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">Visualisierungen</h5>
              <a href="http://www.zoomvp.at/" target="_blank" class="type-underline">
                ZOOM visual project
              </a>
            </span><br>

            <span class="margin-bottom-xs atom-item">
              <h5 class="type-headline-pre">Fotografie</h5>
              <a href="http://www.julianmullan.com/" target="_blank" class="type-underline">
                Julian Mullan
              </a>
            </span><br>

          </p>
        </div>

      </div>
    </section>


    <section class="org-section margin-bottom-medium type-coral">
      <div class="mol-grid-row">
        <div class="grid-s-03-span-08">
          <h1 class="type-headline-01">Disclaimer</h1>    
        </div>

        <div class="grid-s-03-span-04">
          <p class="type-copy">
            Wir weisen darauf hin, dass die Planung für das hier vorgestellte Bauprojekt noch nicht final abgeschlossen ist und sich im Laufe der weiteren Entwicklung der Planung und / oder der Bauausführung ändern kann, wenn dies sinnvoll oder erforderlich ist. Die Darstellungen und Angaben auf dieser Website in Wort und Bild können daher vom fertiggestellten Projekt abweichen. In den Darstellungen abgebildete Einrichtungsgegenstände gehören nicht zum Ausstattungsumfang, sondern sind lediglich als Gestaltungsvorschlag zu verstehen.
          </p>
        </div>

      </div>
    </section>


    <section class="org-section margin-bottom-medium type-coral">
      <div class="mol-grid-row">
        <div class="grid-s-03-span-08">
          <h1 class="type-headline-01">Haftungs&shy;aus&shy;schluss</h1>    
        </div>

        <div class="grid-s-03-span-04">
          <p class="type-copy">
            Dieser Disclaimer und Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
          </p>
        </div>

      </div>
    </section>




    <CompContact />
    <CompFooter />
    
  </div>
</template>

<script>

export default {
  name: 'imprint',
  data() {
    return {

    }
  },
  metaInfo: {
    title: 'Impressum',
    htmlAttrs: {
      lang: 'de',
    },
    meta: [
      {name: 'robots', content: `index, follow`}
    ],
  },
  components: {
    
  },
  mounted() {

  }
}
</script>

<style lang="scss">

.temp-imprint {
  padding-top: calc(var(--spacer) * 5.2);

  @media screen and (min-width: #{$breakpoint-tablet}) {
    padding-top: calc(var(--spacer) * 5);
  }

  .atom-item {
    display: inline-block;

    a {
      display: inline-block;
    }

    .type-headline-pre {
      padding-bottom: 0;
      line-height: 120%;
      line-height: calc( calc(15px + var(--spacer)*.05)*1.4 );
    }
  }
}
</style>