import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CompButton from '@/components/CompButton.vue'
import CompContact from '@/components/CompContact.vue'
import CompFooter from '@/components/CompFooter.vue'

import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.component('CompButton', CompButton)
Vue.component('CompContact', CompContact)
Vue.component('CompFooter', CompFooter)

Vue.use(VueMeta, {})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
