<template>
  <div id="app">

    <CompNavigation />

    <transition name="fade" mode="out-in" appear>
      <router-view id="router"/>
    </transition>

  </div>
</template>

<script>
import CompNavigation from '@/components/CompNavigation.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    CompNavigation,
  },
  metaInfo: {
    titleTemplate: '%s | Hommage au Cottage Eigentumswohnungen in der Messerschmidtgasse 3, 1180 Wien',
    htmlAttrs: {
      lang: 'de',
    },
  },
  methods: {
    sizeHeadline01: () => {

        let headlines01 = document.querySelectorAll('.type-headline-01')
        let fontCalculate = (window.innerWidth/900 * window.innerWidth) - 300;

        let fontWidth = () => {
          if (fontCalculate < 600) {
            return fontCalculate
          } else {
            return 600
          }
        };

        headlines01.forEach((headline) => {
          headline.style.fontVariationSettings = `'wght' 550, 'wdth' ${fontWidth()}`;
        });
    },
  },
  watch: {
    $route (to, from) {
      setTimeout(()=>{ 
        this.sizeHeadline01();
      }, 999);
    }
  },
  mounted() {
    this.sizeHeadline01();
    window.addEventListener("resize", () => requestAnimationFrame(this.sizeHeadline01()));

    let firstImage = document.querySelector('.atom-slider')
    // Check if a slider exist
    // If so, wait till the first slider image has loaded to show the image and navigation
    // REWRITE WITH ASYNCH FUNCTION
    if (firstImage) {
      firstImage.onload = () => {
        this.$store.commit('loadedFirstImage')
      }
    } else {
      this.$store.commit('loadedFirstImage')
    }
  },
  updated() {
    // setTimeout(()=>{ 
    //   this.sizeHeadline01();
    // }, 1000);
  }
}

</script>

<style lang="scss">

@import 'reset-css';
@import './assets/css/variables.scss';
@import './assets/css/layout.scss';
@import './assets/font/font.css';
@import './assets/css/typography.scss';
@import './assets/css/animation.scss';
@import './assets/css/slick.css';

html {
  overflow-x: hidden;
}

body, html {
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -o-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}


</style>
